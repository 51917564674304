import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import HeaderContainer from "../containers/headerContainer";
import Menu from '../pages/Menu/index'
import "./index.sass";

function Calculator() {
    return (
        <AnimatePresence mode="wait">
            <HeaderContainer><Menu /></HeaderContainer>
        </AnimatePresence>
    );
}

export default observer(Calculator);