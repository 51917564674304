import {
    makeAutoObservable,
    toJS
} from "mobx";

class accountstore {
    constructor() {
        makeAutoObservable(this);
    }

    endpoint = "https://waxtestnet.greymass.com";
    stage = "login";
    intermediate_stage = null;
    userLevel = null;
    accountAddress = null;
    balance = null;
    loading = false;
    userData = [];
    stage_history = [];
    stage_history = [];
    intermediate_stage = null;
    accountAddress = null;
    userBalance = [];
    userData = [];
    tableData = [];
    wallet = null;

    get_loading() {
        return this.loading;
    }

    getUserData() {
        return this.userData;
    }

    getTableData() {
        return this.tableData;
    }

    getStageHistory() {
        return this.stage_history;
    }
    getStage() {
        return this.stage;
    }

    getWallet(){
        return this.wallet;
    }

    clearStageHistory() {
        this.stage_history = [];
        this.tempStoreData = "";
    }

    clearAllData() {
        this.accountAddress = null;
        this.userData.cpu = [];
        this.userData.user = [];
    }

    clearIntermidiateStage() {
        this.intermediate_stage = null;
    }

    setUserData(val) {
        this.userData = val;
    }

    setTableData(val) {
        this.tableData = val;
    }

    setStage(val) {
        this.stage = val;
        this.stage_history.push(val);
        if (val === "menu") {
            this.clearStageHistory();
        }
    }

    setWallet(val){
        this.wallet = val;
    }

    setPreviousStage() {
        this.stage_history.pop();
        this.stage =
            this.stage_history.length > 0 ?
            this.stage_history[this.stage_history.length - 1] :
            "menu";
    }

    set_loading(value) {
        this.loading = value;
    }
}

export default new accountstore();