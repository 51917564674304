import React, { useState, useEffect, useRef } from "react";
import {
    TextField,
    Typography,
    Box,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Stack,
    MenuProps,
    FormControlLabel,
    Switch,
} from '@mui/material';
import { styled } from '@mui/system';
import FunctionStore from "../../store/functionstore";
import Preloader from "../preloader";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import './index.sass';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    bgcolor: 'rgba(0, 0, 0, 0.8)',
    border: '2px solid white',
    borderRadius: '1em',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    fontFamily: 'Orbitron',
    color: 'white',
    overflowY: 'scroll',

    '@media (max-width: 600px)': {
        width: '90vw',
        height: '70vh',
        fontSize: '14px',
    },
};

export default function DivWithSpans(props) {
    const [config, setConfig] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [o2, setO2] = useState(0);
    const [water, setWater] = useState(0);
    const [mwh, setMwh] = useState(0);
    const [H2, setH2] = useState(0);
    const [He3, setHe3] = useState(0);
    const [cc, setCC] = useState(0);
    const [nftData, setNftData] = useState([]);
    const [num, setNum] = useState([]);
    const [totalNFt, setTotalNft] = useState(0);
    const [userInventory, setUserInventory] = useState([]);
    const [open, setOpen] = useState(false);
    const [replaceIndex, setReplaceIndex] = useState(null);
    const handleTotalNft = (value) => setTotalNft(value);
    const handleOpen = () => {
        if(corporationFilter === ''){
            FunctionStore.handleError('Please select a corporation');
            return;
        }
        setOpen(true);
    }
    const handleClose = () => setOpen(false);
    const [filteredConfig, setFilteredConfig] = useState([]);
    const [rarityFilter, setRarityFilter] = useState('');
    const [corporationFilter, setCorporationFilter] = useState('');
    const [rarityOptions, setRarityOptions] = useState([]);
    const [corporationOptions, setCorporationOptions] = useState([]);
    const corporation_fixed_options = [
        "Aquarius",
        "Phoenix",
        "Terra",
        "Pegasus",
        "Crime"
    ];
    const [checked, setChecked] = useState(false);
    const handleReset = () => { setNftData([]); setO2(0); setWater(0); setMwh(0); setH2(0); setHe3(0); setCC(0); setPage(0); setRarityFilter(''); setCorporationFilter(''); setFilteredConfig(config); setRarityOptions(Array.from(new Set(config?.map((item) => item.rarity)))); setCorporationOptions(Array.from(new Set(props.config?.flatMap((item) => [item.corporation, item.syndicate]).filter(Boolean)))); setNum([]); handleTotalNft(0);}
    const handleToggle = () => {
        setChecked(!checked);
    };

    const handleRarityFilterChange = (event) => {
        setRarityFilter(event.target.value);
    };

    const handleCorporationFilterChange = (event) => {
        setCorporationFilter(event.target.value);
        setNftData([]);
        setO2(0);
        setWater(0);
        setMwh(0);
        setH2(0);
        setHe3(0);
        setCC(0);
        setPage(0);
        setRarityFilter('');
        setFilteredConfig(config);
        handleTotalNft(0);
        setRarityOptions(Array.from(new Set(config?.map((item) => item.rarity))));
    };

    const handleInputChange = (event, index) => {
        setNum((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = event.target;
            return newVal;
        });
    }

    const handleInc = (index) => {
        setNum((prevVal) => {
            var currentValue = prevVal[index] ?? 1;
            if (typeof currentValue !== 'number' || isNaN(currentValue)) {
                FunctionStore.handleError('Invalid value');
                return prevVal;
            }

            var value = currentValue + 1;
            if (totalNFt + 1 > 64) {
                FunctionStore.handleError('NFTs cannot be greater than 64');
                return prevVal;
            }
            handleTotalNft(totalNFt + 1);
            const newVal = [...prevVal];
            newVal[index] = value;
            return newVal;
        });
    };

    const handleDec = (index) => {
        setNum((prevVal) => {
            const currentValue = prevVal[index] ?? 1;
            if (typeof currentValue !== 'number' || isNaN(currentValue)) {
                FunctionStore.handleError('Invalid value');
                return prevVal;
            }

            const value = currentValue - 1;
            if (value < 1) {
                FunctionStore.handleError('Value cannot be less than 1');
                return prevVal;
            }
            handleTotalNft(totalNFt - 1);
            const newVal = [...prevVal];
            newVal[index] = value;
            return newVal;
        });
    };

    const applyFilters = () => {
        if (config) {
            if (!corporationFilter) {
                FunctionStore.handleError("Please Select Corporation First");
                return;
            }
            const filteredData = config.filter((item, index) => {
                const isCorporationMatch = (item.corporation === corporationFilter || item.corporation === "Crime");
                const isRarityMatch = rarityFilter ? item.rarity === rarityFilter : true;
                return isCorporationMatch && isRarityMatch;
            });
            setFilteredConfig(filteredData);
        }
    };


    const CustomSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase': {
            color: '#ff000067',
            '&.Mui-checked': {
                color: '#008000',
            },
        },
        '& .MuiSwitch-thumb': {
            borderRadius: '1em',
        },
        '& .MuiSwitch-track': {
            backgroundColor: '#ccc',
        },
    }));

    useEffect(() => {
        applyFilters();
    }, [rarityFilter, corporationFilter]);

    useEffect(() => {
        if (props.config.length !== 0) {
            setConfig(props.config);
            setRarityOptions(Array.from(new Set(props.config?.map((item) => item.rarity).filter(Boolean))));
            let corporation_options = Array.from(new Set(props.config?.flatMap((item) => [item.corporation, item.syndicate]).filter(Boolean)));
            corporation_options > 2 ? setCorporationOptions(corporation_options) : setCorporationOptions(corporation_fixed_options);
        }
        if (props.data.length > 0) {
            setUserInventory(
                props.config?.filter((configItem) =>
                    props.data?.some((dataItem) => dataItem.template_id === configItem.template_id)
                )
            );
        }
    }, [props.config, props.data]);

    useEffect(() => {
        if (nftData) {
            const balances = {
                OTWO: 0,
                WATER: 0,
                MWH: 0,
                HTWO: 0,
                HEL: 0,
                CC: 0
            };
            for (let i = 0; i < nftData.length; i++) {
                const item = nftData[i];
                item.token_out?.forEach((balance) => {
                    Object.keys(balances).forEach((key) => {
                        if (balance.includes(key)) {
                            balances[key] += parseFloat(balance) * (num[i] ?? 1);
                        }
                    });
                });
                item.token_in?.forEach((balance) => {
                    Object.keys(balances).forEach((key) => {
                        if (balance.includes(key)) {
                            balances[key] -= parseFloat(balance) * (num[i] ?? 1);
                        }
                    });
                });
            }
            setO2(balances.OTWO);
            setWater(balances.WATER);
            setMwh(balances.MWH);
            setH2(balances.HTWO);
            setHe3(balances.HEL);
            setCC(balances.CC);
        }
    }, [nftData, num]);
    if (config == null)
        return <Preloader />

    const colors = [
        { name: "Metal", hexCode: "#3e3d3d" },
        { name: "Jadeite", hexCode: "#1B2D2D" },
        { name: "Silver", hexCode: "#808080" },
        { name: "Gold", hexCode: "#B8860B" },
        { name: "Helium", hexCode: "#4B0082" },
        { name: "Dark Energy", hexCode: "#ffc857" }
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 8));
        setPage(0);
    };
    const totalRows = 8;
    const numPalletes = colors.length;
    const numRowsPerPage = 8;
    const emptyRows = numRowsPerPage - Math.min(numRowsPerPage, nftData.length - page * numRowsPerPage);
    var displayData = nftData?.slice(page * numRowsPerPage, page * numRowsPerPage + numRowsPerPage);
    const sum = num.length === 0 ? nftData.length : num.reduce((accumulator, currentValue) => accumulator + (currentValue ?? 0), 0);

    return (
        <>
            <TableContainer className="tableContainer" component={Paper}
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.845)',
                    padding: '0.5em',
                    border: '2px solid #ffde59',
                    borderRadius: '.5em'
                }}>
                <Table sx={{ minWidth: '60vw', maxWidth: '70vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={8} style={{ border: 'none' }}>
                                <FormControl className="formControl">
                                    <InputLabel id="corporation-filter-label" sx={{ color: 'white', fontFamily: 'Orbitron' }}>Choose Corporation</InputLabel>
                                    <Select
                                        labelId="corporation-filter-label"
                                        id="corporation-filter"
                                        value={corporationFilter}
                                        onChange={handleCorporationFilterChange}
                                        sx={{ color: 'white', fontFamily: 'Orbitron' }}
                                    >
                                        {corporationOptions.map((option) => (
                                            <MenuItem key={option} value={option} style={{ fontFamily: 'Orbitron' }}>
                                                {option}
                                            </MenuItem>
                                        ))
                                        }
                                    </Select>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ border: 'none' }}><button className="reset" style={{ width: '100%', height: '3em' }} onClick={handleReset}>Reset</button></TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#a8e6ff67',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >O2</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#63a0fb67',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >H20</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#ffae009d',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >MWH</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#ff000067',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >H2</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#FF28F467',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >He3</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: 'rgba(147, 196, 125, 0.35)',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >CC</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: 'rgba(255,255,255,0.5)',
                                    borderRadius: '1em 1em 0 0',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >NFTs</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    textAlign: 'center',
                                    backgroundColor: '#5a4766c9',
                                    borderRadius: '.5em 0 0 .5em',
                                    fontSize: '1.25em',
                                    border: 'none',
                                    textTransform: 'uppercase'
                                }}
                            >Total</TableCell>
                            <TableCell
                                style={{
                                    color: o2 ? o2 > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#a8e6ff67',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{o2}</TableCell>
                            <TableCell
                                style={{
                                    color: water ? water > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#63a0fb67',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{water}</TableCell>
                            <TableCell
                                style={{
                                    color: mwh ? mwh > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#ffae009d',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{mwh}</TableCell>
                            <TableCell
                                style={{
                                    color: H2 ? H2 > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#ff000067',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{H2}</TableCell>
                            <TableCell
                                style={{
                                    color: He3 ? He3 > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: '#FF28F467',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{He3}</TableCell>
                            <TableCell
                                style={{
                                    color: cc ? cc > 0 ? 'green' : 'red' : 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: 'rgba(147, 196, 125, 0.35)',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{cc}</TableCell>
                            <TableCell
                                style={{
                                    color: 'white',
                                    fontFamily: 'Orbitron',
                                    backgroundColor: 'rgba(255,255,255,0.5)',
                                    textAlign: 'center',
                                    border: '3px solid black',
                                    fontSize: '1em',
                                    fontWeight: 'bold'
                                }}
                            >{totalNFt}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayData.map((item, index) => (
                            <TableRow key={index} style={{ borderBottom: 'none' }}>
                                <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}
                                    onClick={() => {
                                        setReplaceIndex(index);
                                        handleOpen();
                                    }}
                                >
                                    <div className="nft" style={{ backgroundColor: colors.find((color) => color.name === item.rarity)?.hexCode }}>
                                        <Typography fontFamily="Orbitron" textAlign="left" variant="h5">
                                            {item?.name ?? ''}
                                        </Typography>
                                        <Typography fontFamily="Orbitron" textAlign="left" fontSize="1em">
                                            {item?.corporation ?? ''}
                                        </Typography>
                                        <div className="rarity-palette" style={{ "--num-palettes": numPalletes }}>
                                            {colors.map((color, colorIndex) => (
                                                <span
                                                    className={`palette ${item?.rarity === color.name ? '' : 'rarity'}`}
                                                    key={colorIndex}
                                                    style={{
                                                        backgroundColor: item?.rarity === color.name ? color.hexCode : `${color.hexCode}33`,
                                                    }}
                                                ></span>
                                            ))}
                                        </div>
                                    </div>
                                </TableCell>
                                {['OTWO', 'WATER', 'MWH', 'HTWO', 'HEL', 'CC'].map((token) => (
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }} key={token}>
                                        {item?.token_out?.find((balance) => balance.includes(token)) ? (
                                            <Typography fontFamily="Orbitron" textAlign="center" variant="h5" color="green">
                                                +{(parseFloat(item?.token_out?.find((balance) => balance.includes(token))) || 0) * (num[index] ?? 1)}
                                            </Typography>
                                        ) : item?.token_in?.find((balance) => balance.includes(token)) ? (
                                            <Typography fontFamily="Orbitron" textAlign="center" variant="h5" color="red">
                                                -{(parseFloat(item?.token_in?.find((balance) => balance.includes(token))) || 0) * (num[index] ?? 1)}
                                            </Typography>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                ))}

                                <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1em' }}>
                                        <div className="input-group-prepend">
                                            <button className="btn btn-outline-primary" type="button" style={{
                                                fontWeight:'bold',
                                                fontFamily:'Orbitron',
                                                color:'white'}}
                                                onClick={() => handleDec(index)}>
                                                {'<'}
                                            </button>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={num[index] || 1}
                                            readOnly
                                            onChange={(event) => handleInputChange(event, index)}
                                            style={{
                                                background:'transparent',
                                                color:'white',
                                                textAlign:'center',
                                                width:'4em',
                                                fontWeight:'bold',
                                                fontFamily:'Orbitron',
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-primary" type="button" style={{
                                                fontWeight:'bold',
                                                fontFamily:'Orbitron',
                                                color:'white'
                                            }} onClick={() => handleInc(index)}>
                                                {'>'}
                                            </button>
                                        </div>
                                    </div>

                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            Array.from({ length: Math.max(8 - displayData.length, 0) }).map((_, index) => (
                                <TableRow key={`empty-${index}`}>
                                    <TableCell className="paddingClass" style={{ borderBottom: 'none' }}>
                                        <button className="add" onClick={handleOpen} disabled={sum >= 64}>+ Add</button>
                                    </TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                    <TableCell className="paddingClass" style={{ backgroundColor: 'rgba(90, 71, 102, 0.3)', borderBottom: 'none' }}></TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table >
            </TableContainer >
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems={'center'}>
                        <Typography variant="h6" component="h2" fontFamily={'Orbitron'} textAlign={'center'}>
                            Select NFT Template
                        </Typography>
                        <FormControlLabel
                            control={
                                <CustomSwitch checked={checked} onChange={handleToggle}/>
                            }
                            label={
                                <Typography variant="body1" style={{ fontFamily: 'Orbitron' }}>
                                    Select from Inventory
                                </Typography>
                            }
                        />
                        <div className="filter-container">
                            <FormControl sx={{ width: '10em', border: '1px solid white', borderRadius: '.5em' }}>
                                <InputLabel id="rarity-filter-label" sx={{ color: 'white', fontFamily: 'Orbitron' }}>Rarity</InputLabel>
                                <Select
                                    labelId="rarity-filter-label"
                                    id="rarity-filter"
                                    value={rarityFilter}
                                    onChange={handleRarityFilterChange}
                                    sx={{ color: 'white', fontFamily: 'Orbitron' }}>
                                    <MenuItem value="">All</MenuItem>
                                    {rarityOptions.map((option) => (
                                        <MenuItem key={option} value={option} style={{ fontFamily: 'Orbitron' }}>
                                            {option}
                                        </MenuItem>
                                    ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <Box className="nft-template-container">

                            {filteredConfig.map((item, index) => {
                                if (checked) {
                                    const isPresentInInventory = checked && userInventory.some(
                                        (inventoryItem) => inventoryItem.template_id === item.template_id
                                    );
                                    if (isPresentInInventory) {
                                        return (
                                            <div className="nft-template" key={index} onClick={() => {
                                                if(replaceIndex == null){
                                                    setNftData((prevData) => [...prevData, item]);
                                                    handleTotalNft(totalNFt+1);
                                                }
                                                else{
                                                    const temp = [...nftData];
                                                    temp[replaceIndex] = item;
                                                    setNftData(temp);
                                                }
                                                handleClose();
                                                setReplaceIndex(null);
                                            }}>
                                                <img className="nft-img" src={item.img ?? ''} alt="" />
                                                <Typography fontFamily="Orbitron" textAlign="center">
                                                    {item.name ?? ''}
                                                </Typography>
                                            </div>
                                        );
                                    }
                                    return null;
                                }
                                else {
                                    return (
                                        <div className="nft-template" key={index} onClick={() => {
                                            if(replaceIndex == null){
                                                setNftData((prevData) => [...prevData, item]);
                                                handleTotalNft(totalNFt+1);
                                            }
                                            else{
                                                const temp = [...nftData];
                                                temp[replaceIndex] = item;
                                                setNftData(temp);
                                            }
                                            handleClose();
                                            setReplaceIndex(null);
                                        }}>
                                            <img className="nft-img" src={item.img ?? ''} alt="" />
                                            <Typography fontFamily="Orbitron" textAlign="center" variant="body1">
                                                {item.name ?? ''}
                                            </Typography>
                                        </div>
                                    );
                                }
                            })
                            }
                        </Box>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}