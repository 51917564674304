import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.sass';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';
import { Wombat } from 'wombat-ual';
import AppRoutes from './routes';
const myChain = {
  chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
  rpcEndpoints: [
    {
      protocol: 'https',
      host: 'wax.greymass.com',
      port: '',
    },
  ],
};

const wax = new Wax([myChain], { appName: 'mmhe3' });
const anchor = new Anchor([myChain], {
  appName: 'mmhe3',
});
const wombat = new Wombat([myChain], { appName: 'mmhe3' })
const MyUALConsumer = withUAL(App);
const renderApp = () => (
  <UALProvider
    chains={[myChain]}
    authenticators={[wax,anchor, wombat]}
    appName="mmhe3"
  >
    <MyUALConsumer />
    <AppRoutes />
  </UALProvider>
);
const rootElement = document.getElementById('root');
createRoot(rootElement).render(renderApp());

reportWebVitals();
