import "./index.sass";
import {observer} from "mobx-react";
import Header from "../header/header";
import './style.css'

function ContentBox({children}) {
    return (
        <div className="content-box">
            <Header/>
            <div className="content-box-content" >
                {children}
            </div>
        </div>
    );
}

export default observer(ContentBox)