import "./index.sass"
import {Circles} from 'react-loader-spinner'

export default function Preloader(props){
    return (
        <div className="preloader">
            <Circles
            height="80"
            width="80"
            color="white"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            />
            <h6 className="preloader-text">{typeof(props.message) != 'undefined' || Object.keys(props).length > 0 ? props.message : "Loading"}</h6>
        </div>
    )
}