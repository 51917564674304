import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import App from "./App";
import Calculator from './Calculator';
import Notification from "./components/notification";

export default function AppRoutes() {
    return (
        <App>
            <Router>
                <Notification/>
                <Routes>
                    <Route path="/" element={<Calculator props/>}/>
                </Routes>
            </Router>
        </App>
    );
}