import {
    makeAutoObservable,
    toJS
} from "mobx";
import AccountStore from "./accountStore";
import * as waxjs from "@waxio/waxjs/dist";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import {
    toast
} from "react-toastify";

class functionstore {
    constructor() {
        makeAutoObservable(this);
        this.fetchAllData();
    }
    endpoint = "https://waxapi.ledgerwise.io";
    endpoint_arr = [
        "https://wax.api.eosnation.io",
        "https://wax.eosusa.io",
        "https://api.wax.liquidstudios.io",
        "https://wax.cryptolions.io",
        "https://wax.eu.eosamsterdam.net",
        "https://wax.eoseoul.io",
        "https://api.wax.alohaeos.com",
        "https://wax.csx.io",
        "https://wax.eosphere.io",
        "https://wax-bp.wizardsguild.one"
    ];
    atomic_api = "https://wax-aa.eu.eosamsterdam.net";
    ipfs_api = "https://atomichub-ipfs.com/ipfs/";
    wax = new waxjs.WaxJS(this.endpoint, null, null, false);
    transport = new AnchorLinkBrowserTransport();
    anchorLink = new AnchorLink({
        transport: this.transport,
        chains: [{
            chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
            nodeUrl: this.endpoint,
        },],
    });
    dapp = "MMHe3Calculator";
    collectionName = "moonminingh3";
    contract = "moonmhe3game";
    path = "/v1/chain/get_table_rows";
    eosjsName = require("eosjs-account-name");
    accountAddressUint64 = "";


    handleError(e) {
        AccountStore.set_loading(false);
        toast.error(
            e.message ?
                e.message.includes("undefined") ?
                    "Failed to fetch!" :
                    e.message :
                e, {
            autoClose: 10000
        }
        );
        console.log(e)
    }

    handleSuccess(message) {
        toast.success(message);
    }

    async Login(data) {
        AccountStore.setTableData(data);
        AccountStore.accountAddress = data.accountName;
        this.accountAddressUint64 = this.eosjsName.nameToUint64(AccountStore.accountAddress).toString();
        await this.Refresh();
    }

    async Refresh() {
        try {
            AccountStore.balance = await this.getBalance(AccountStore.accountAddress);;
            var userData = AccountStore.getUserData();
            userData.config = userData.config ? userData.config : await this.getConfig();
            let temp = await this.getAssets();
            temp = temp.filter((item) => item.schema_name !== "moon.mineral")
                    .filter((item) => item.schema_name !== "blend.token")
                    .filter((item) => item.schema_name !== "collectible")
                    .filter((item) => item.schema_name !== "packs");
            var filteredData = [];
            for(const d of userData.config){
                for(const t of temp){
                    if(d.template_id == t.template_id) 
                    filteredData.push({
                        ...d,
                        inventory: true
                    });
                }
            };
            userData.user = filteredData;
            AccountStore.setUserData(userData);
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getAssets() {
        try {
            const obj = [];
            let currentPage = 1;
            let totalPages = 1;

            while (currentPage <= totalPages) {
                const path =
                    "atomicassets/v1/assets?collection_name=" +
                    this.collectionName +
                    "&owner=" +
                    AccountStore.accountAddress +
                    "&page=" +
                    currentPage +
                    "&limit=1000&order=desc&sort=asset_id";

                const response = await fetch(this.atomic_api + "/" + path, {
                    headers: {
                        "Content-Type": "text/plain",
                    },
                    method: "POST",
                });

                const body = await response.json();
                const data = Object.values(body.data);

                for (const asset of data) {
                    obj.push({
                        asset_id: asset.asset_id,
                        template_id: asset.template?.template_id,
                        schema_name: asset.schema?.schema_name,
                    });
                }

                if (body.page && body.page.total) {
                    totalPages = body.page.total;
                } else {
                    break;
                }

                currentPage++;
            }

            return obj;

        } catch (e) {
            this.handleError(e);
        }
    }


    async getBalance(account) {
        try {
            var data = JSON.stringify({
                json: true,
                code: 'eosio.token',
                scope: account,
                table: "accounts",
                limit: 1000,
            });

            const response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST",
            });
            const body = await response.json();
            if (body.rows.length > 0) {
                for (const d of body.rows) {
                    if (d.balance.includes('WAX')) return parseFloat(d.balance).toFixed(4) + " WAX";
                    else return "0.0000 WAX";
                }
            }
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getConfig() {
        try {

            let fetch_more = true;
            let next_key;
            let result = [];

            while (fetch_more) {
                var data = JSON.stringify({
                    json: true,
                    code: this.contract,
                    scope: this.contract,
                    table: "bconfigs",
                    limit: 2000,
                    lower_bound: next_key,
                });
    
                const response = await fetch(this.endpoint + this.path, {
                    headers: {
                        "Content-Type": "text/plain"
                    },
                    body: data,
                    method: "POST",
                });
                const body = await response.json();

                if (typeof body.rows[0] == 'undefined') {
                    this.endpoint = this.endpoint_arr[Math.floor(Math.random() * this.endpoint_arr.length)];
                    this.wax = new waxjs.WaxJS(this.endpoint, null, null, false);
                    this.anchorLink = new AnchorLink({
                        transport: this.transport,
                        chains: [{
                            chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
                            nodeUrl: this.endpoint,
                        },],
                    });
                    this.handleSuccess("Switched to another endpoint!");
                    return await this.getConfig();
                }
                else {
                    var templates = await this.getTemplateinfo();
                    if (body.rows.length > 0) {
                        for (const data of body.rows) {
                            var filtered = templates.filter((t) => t.template_id == data.template_id)[0];
                            result.push({
                                ...data,
                                img: this.ipfs_api + filtered?.immutable_data.img,
                                name: filtered?.immutable_data.name,
                                rarity: filtered?.immutable_data.rarity,
                                type: filtered?.immutable_data.type,
                                corporation: filtered?.immutable_data.corporation ?? filtered?.immutable_data.syndicate ?? null
                            });
                        }
                    }
                    
                    if(body.more)
                        next_key = body.next_key;
                    else
                        fetch_more = false;
                }
            }
            return result;
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getTemplateinfo() {
        const templates = [];
        let page = 1;
        let hasNextPage = true;

        while (hasNextPage) {
            const path = `atomicassets/v1/templates?collection_name=${this.collectionName}&page=${page}&limit=1000&order=desc&sort=created`;
            const response = await fetch(`${this.atomic_api}/${path}`, {
                headers: {
                    "Content-Type": "text/plain"
                },
                method: "POST",
            });
            const body = await response.json();
            templates.push(...body.data);

            if (body.data.length < 1000) {
                hasNextPage = false;
            } else {
                page++;
            }
        }
        return templates;
    }

    async fetchAllData() {
        AccountStore.set_loading(true);
        var data = {
            config: await this.getConfig(),
            user: []
        }
        AccountStore.setUserData(data);
        AccountStore.set_loading(false);
    }
}

export default new functionstore();
