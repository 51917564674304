import { observer } from "mobx-react";
import { useState, useEffect, useRef } from "react";
import StoreContext from "../../store/rootstore";
import ContentBox from "../../components/contentBox";
import Preloader from "../../components/preloader";
import "./index.sass";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Table from '../../components/tableui_multiple';
function Menu() {
    const { AccountStore, FunctionStore } = StoreContext();
    const [data,setData] = useState(null);

    const fetchData = async () => {
        var data = AccountStore.getUserData();
        setData(data);
    }
    useEffect(() => {
        if (!AccountStore.get_loading()) fetchData();
    }, [AccountStore.get_loading()]);

    if (AccountStore.get_loading() || data == null)
        return <Preloader />

    return (
        AccountStore.get_loading() ? <Preloader /> :
            <div className="menu">
                <ContentBox>
                    <Table config={data?.config} data={data?.user}/>
                </ContentBox>
            </div>
    )
}
export default observer(Menu)