import "./header.sass"
import { useContext,useEffect } from "react";
import StoreContext from "../../store/rootstore";
import "../../pages/Login/index.sass";
import { observer } from "mobx-react";
import { UALContext } from "ual-reactjs-renderer";

function Header() {
    const { AccountStore,FunctionStore } = StoreContext();
    const ual = useContext(UALContext);

    useEffect(() => {
        if (ual.activeUser) FunctionStore.Login(ual.activeUser);
    },[ual.activeUser]);

    const login = () => {
        ual.logout();
        ual.showModal();
    }

    const logout = () => {
        ual.logout();
        AccountStore.clearAllData();
        window.location.reload();
    }

    const handleClickLog = () => {
        if (AccountStore.accountAddress == null)
            login();
        else
            logout();
    }

    return (
        <div className="header-bar">
            <img className="logo" src={require('../../assets/logo.png')} />
            <div className="row-flex">
                {AccountStore.accountAddress != null ?
                    <div className="child">
                        <span style={{fontSize:'1em',color:'white'}}>{AccountStore.accountAddress}</span>
                        <span style={{fontSize:'.8em',color:'#4fcfb0'}}>{AccountStore.balance}</span>
                    </div>
                    :
                    ''}
                <button className="logbutton"
                    onClick={() => {handleClickLog();}}
                >
                    {AccountStore.accountAddress == null ? "Connect" : "Logout"}
                </button>
            </div>
        </div>
    );
}
export default observer(Header)